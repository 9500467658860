var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('textarea', {
    ref: "taskTextArea",
    staticClass: "task-text-area",
    class: {
      'task-text-area-empty': !this.value && !_vm.error,
      'task-text-area-filled': this.value && !_vm.error,
      'task-text-area-error': _vm.error,
      'task-text-required': _vm.required
    },
    attrs: {
      "oninput": "this.style.height = \"\";this.style.height = (this.scrollHeight + 2) + \"px\"",
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function (e) {
        return _vm.emitValue(e);
      }
    }
  }), _vm._v(" "), _vm.showPlaceholder ? _c('span', {
    staticClass: "task-text-area-placeholder",
    on: {
      "click": _vm.focusTextArea
    }
  }, [_vm._v(_vm._s(_vm.placeholder)), _vm.required ? _c('span', {
    staticStyle: {
      "color": "#FF1E24"
    }
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _vm.error ? _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "color": "#ff1e24"
    }
  }, [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }