var render = function render(){
  var _vm$linkedObject, _vm$linkedObject2, _vm$task, _vm$task$linkedDevelo, _vm$linkedObject3, _vm$linkedObject4, _vm$task2, _vm$task2$linkedProje;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-draggable-resizable', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function () {
        return _vm.closeWidget(_vm.taskToEdit);
      },
      expression: "() => closeWidget(taskToEdit)"
    }],
    staticClass: "widget-container",
    attrs: {
      "z": 99,
      "resizable": false,
      "drag-handle": ".drag-area",
      "h": "auto"
    }
  }, [_c('div', {
    staticClass: "task-add-widget-header drag-area"
  }, [_c('img', {
    attrs: {
      "width": "104",
      "height": "11",
      "src": require("@/assets/images/menu.svg"),
      "alt": ""
    }
  })]), !_vm.loading ? [_c('div', {
    key: 'widget-content-' + _vm.updateWidget,
    staticClass: "task-add-widget-content"
  }, [_c('div', {
    staticClass: "task-add-widget-content-category"
  }, [_c('TaskTypeSelection', {
    attrs: {
      "type": 'primary'
    },
    model: {
      value: _vm.task.category,
      callback: function ($$v) {
        _vm.$set(_vm.task, "category", $$v);
      },
      expression: "task.category"
    }
  }), _c('TaskCategorySelection', {
    attrs: {
      "type": _vm.task.category
    },
    model: {
      value: _vm.task.type,
      callback: function ($$v) {
        _vm.$set(_vm.task, "type", $$v);
      },
      expression: "task.type"
    }
  }), _vm.task.category === 'open_house' ? _c('div', {
    staticClass: "open-house-info"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/infoMark20.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-small-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("OpenHouseInformationNotExported")) + " ")])]) : _vm._e()], 1), _vm.widgetFields.title ? _c('TaskTextInput', {
    attrs: {
      "required": _vm.task.category === 'event',
      "placeholder": _vm.titleText,
      "error": (_vm.task.category === 'task' || _vm.task.category === 'event') && _vm.showErrors && !_vm.isFieldValid('content'),
      "error-message": _vm.$t('FormErrors.FieldRequired')
    },
    model: {
      value: _vm.task.title,
      callback: function ($$v) {
        _vm.$set(_vm.task, "title", $$v);
      },
      expression: "task.title"
    }
  }) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "width": "100%"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticStyle: {
      "border-bottom": "1px solid #e6e8ec",
      "display": "flex",
      "align-items": "center",
      "justify-content": "space-between",
      "padding-bottom": "16px",
      "padding-right": "16px"
    }
  }, [_vm.task.category === 'resource' || _vm.task.category === 'event' || _vm.task.category === 'open_house' || _vm.task.category === 'task' && !_vm.noDueDateTask ? [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "24px"
    }
  }, [_c('TaskDatePicker', {
    attrs: {
      "label": _vm.widgetFields.dueDate ? 'Field.dueDate' : 'Date',
      "full-day-task": _vm.fullDayTask,
      "task-category": _vm.task.category,
      "dates": _vm.task.dates
    },
    on: {
      "change": _vm.chooseNewDate,
      "changeFullDayToggle": _vm.changeFullDayToggle
    }
  }), !_vm.fullDayTask ? _c('div', {
    staticClass: "task-add-widget-content-date-from-to"
  }, [_c('img', {
    attrs: {
      "height": "20",
      "width": "20",
      "src": require('@/assets/images/clock_24_24.svg'),
      "alt": ""
    }
  }), _vm.widgetFields.dateFrom ? _c('div', {
    staticClass: "task-add-widget-content-date-from-to-inputs"
  }, [_c('div', {
    staticClass: "colored-label label12"
  }, [_vm._v(_vm._s(_vm.$t("From")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.task.dates.startTime,
      expression: "task.dates.startTime"
    }],
    staticClass: "content",
    attrs: {
      "type": "time",
      "step": "600"
    },
    domProps: {
      "value": _vm.task.dates.startTime
    },
    on: {
      "blur": _vm.taskStartTimeChanged,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.task.dates, "startTime", $event.target.value);
      }
    }
  })]) : _vm._e(), !_vm.widgetFields.dueTime ? _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "justify-content": "flex-end"
    }
  }, [_c('img', {
    attrs: {
      "height": "20",
      "width": "14",
      "src": require('@/assets/images/arrow_text.svg'),
      "alt": ""
    }
  })]) : _vm._e(), _vm.widgetFields.dateTo || _vm.widgetFields.dueTime ? _c('div', {
    staticClass: "task-add-widget-content-date-from-to-inputs"
  }, [_c('div', {
    staticClass: "colored-label label12"
  }, [_vm._v(" " + _vm._s(_vm.widgetFields.dateTo ? _vm.$t("To") : _vm.$t("DueTime")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.task.dates.endTime,
      expression: "task.dates.endTime"
    }],
    staticClass: "content",
    attrs: {
      "type": "time",
      "step": "600"
    },
    domProps: {
      "value": _vm.task.dates.endTime
    },
    on: {
      "blur": _vm.taskEndTimeChanged,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.task.dates, "endTime", $event.target.value);
      }
    }
  })]) : _vm._e()]) : _vm._e()], 1), _vm.task.category === 'task' ? _c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "width": "20",
      "height": "20",
      "src": require('@/assets/images/close_24_24.svg'),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        _vm.noDueDateTask = true;
      }
    }
  }) : _vm._e()] : _vm._e(), _vm.task.category === 'task' && _vm.noDueDateTask ? _c('div', {
    staticClass: "link-small",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        _vm.noDueDateTask = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddDueDate")) + " ")]) : _vm._e()], 2), _c('div', {
    staticStyle: {
      "padding-top": "16px",
      "display": "flex",
      "flex-direction": "column",
      "gap": "16px",
      "padding-right": "16px"
    }
  }, [_vm.widgetFields.project && (((_vm$linkedObject = _vm.linkedObject) === null || _vm$linkedObject === void 0 ? void 0 : _vm$linkedObject.name) == 'project' || ((_vm$linkedObject2 = _vm.linkedObject) === null || _vm$linkedObject2 === void 0 ? void 0 : _vm$linkedObject2.name) !== 'development') && !((_vm$task = _vm.task) !== null && _vm$task !== void 0 && (_vm$task$linkedDevelo = _vm$task.linkedDevelopment) !== null && _vm$task$linkedDevelo !== void 0 && _vm$task$linkedDevelo.id) ? _c('div', {
    staticClass: "task-add-widget-content-project"
  }, [_c('TaskLinkedToSearch', {
    attrs: {
      "items": _vm.projectSearchItems,
      "label": "Project",
      "icon-name": "projects.svg",
      "not-found-item-name": _vm.task.linkedProject.name,
      "required": _vm.task.category === 'open_house'
    },
    model: {
      value: _vm.task.linkedProject.id,
      callback: function ($$v) {
        _vm.$set(_vm.task.linkedProject, "id", $$v);
      },
      expression: "task.linkedProject.id"
    }
  }), _vm.task.category === 'open_house' && _vm.showErrors && !_vm.isFieldValid('project') ? _c('div', [_c('p', {
    staticClass: "content-normal",
    staticStyle: {
      "color": "#ff1e24",
      "padding-bottom": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("FormErrors.FieldRequired")) + " ")])]) : _vm._e()], 1) : _vm._e(), _vm.widgetFields.development && (((_vm$linkedObject3 = _vm.linkedObject) === null || _vm$linkedObject3 === void 0 ? void 0 : _vm$linkedObject3.name) == 'development' || ((_vm$linkedObject4 = _vm.linkedObject) === null || _vm$linkedObject4 === void 0 ? void 0 : _vm$linkedObject4.name) !== 'project') && !((_vm$task2 = _vm.task) !== null && _vm$task2 !== void 0 && (_vm$task2$linkedProje = _vm$task2.linkedProject) !== null && _vm$task2$linkedProje !== void 0 && _vm$task2$linkedProje.id) ? _c('div', {
    staticClass: "task-add-widget-content-project"
  }, [_c('TaskLinkedToSearch', {
    attrs: {
      "items": _vm.developmentSearchItems,
      "label": "Development",
      "icon-name": "PropertyDeveloper.svg",
      "not-found-item-name": _vm.task.linkedDevelopment.name,
      "required": _vm.task.category === 'open_house'
    },
    model: {
      value: _vm.task.linkedDevelopment.id,
      callback: function ($$v) {
        _vm.$set(_vm.task.linkedDevelopment, "id", $$v);
      },
      expression: "task.linkedDevelopment.id"
    }
  }), _vm.task.category === 'open_house' && _vm.showErrors && !_vm.isFieldValid('development') ? _c('div', [_c('p', {
    staticClass: "content-normal",
    staticStyle: {
      "color": "#ff1e24",
      "padding-bottom": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("FormErrors.FieldRequired")) + " ")])]) : _vm._e()], 1) : _vm._e(), _vm.widgetFields.contact ? _c('div', {
    staticClass: "task-add-widget-content-contact"
  }, [_c('TaskLinkedToSearch', {
    attrs: {
      "not-found-item-name": _vm.task.linkedContact.name,
      "items": _vm.contactSearchItems,
      "label": "Contact",
      "icon-name": "clients.svg",
      "modalType": 'client'
    },
    model: {
      value: _vm.task.linkedContact.id,
      callback: function ($$v) {
        _vm.$set(_vm.task.linkedContact, "id", $$v);
      },
      expression: "task.linkedContact.id"
    }
  })], 1) : _vm._e(), _vm.widgetFields.numberOfParticipants ? _c('div', {
    staticClass: "task-add-widget-content-number-of-participants"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "12px",
      "cursor": "pointer"
    }
  }, [_c('img', {
    attrs: {
      "height": "20",
      "width": "20",
      "src": require('@/assets/images/work_management_24_24.svg'),
      "alt": ""
    },
    on: {
      "click": _vm.addOpenHouseParticipants
    }
  }), !_vm.addOpenHouseParticipantsOpen ? _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    },
    on: {
      "click": _vm.addOpenHouseParticipants
    }
  }, [!_vm.task.numberOfParticipants ? _c('p', {
    staticClass: "link-small",
    staticStyle: {
      "font-size": "12px",
      "line-height": "16px"
    }
  }, [_vm._v(" " + _vm._s("+ " + _vm.$t("Add") + " " + _vm.$t("NumberOfParticipants").toLowerCase()) + " ")]) : _c('p', [_vm._v(" " + _vm._s(_vm.task.numberOfParticipants) + " ")])]) : _vm._e(), _vm.addOpenHouseParticipantsOpen ? _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.task.numberOfParticipants,
      expression: "task.numberOfParticipants"
    }],
    ref: "numberOfParticipants",
    staticClass: "content-normal",
    staticStyle: {
      "outline": "none",
      "height": "36px",
      "border": "1px solid #e6e8ec",
      "border-radius": "8px",
      "padding": "6px 8px"
    },
    attrs: {
      "type": "number",
      "autocomplete": "off",
      "maxlength": "3",
      "placeholder": _vm.$t('NumberOfParticipants')
    },
    domProps: {
      "value": _vm.task.numberOfParticipants
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return $event.target.blur();
      },
      "blur": function ($event) {
        _vm.addOpenHouseParticipantsOpen = false;
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.task, "numberOfParticipants", $event.target.value);
      }
    }
  })]) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "task-add-widget-comment"
  }, [_c('img', {
    staticStyle: {
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "src": require("@/assets/images/chat-no-bubble.svg"),
      "alt": ""
    }
  }), !_vm.task.comment && !_vm.editComment ? _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    },
    on: {
      "click": _vm.openEditComment
    }
  }, [_c('p', {
    staticClass: "link-small",
    staticStyle: {
      "font-size": "12px",
      "line-height": "16px"
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t("addComment").charAt(0).toUpperCase() + _vm.$t("addComment").slice(1)) + " ")])]) : _vm._e(), _vm.editComment ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.task.comment,
      expression: "task.comment"
    }],
    ref: "commentbox",
    staticClass: "task-textarea content-normal",
    staticStyle: {
      "white-space": "pre-wrap"
    },
    attrs: {
      "id": "textarea",
      "rows": "5"
    },
    domProps: {
      "value": _vm.task.comment
    },
    on: {
      "focusout": _vm.closeEditComment,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.task, "comment", $event.target.value);
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.task.comment && !_vm.editComment ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column"
    },
    on: {
      "click": _vm.openEditComment
    }
  }, [_c('div', {
    staticClass: "task-comment-header"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("Comment")))])]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "white-space": "pre-wrap",
      "width": "370px",
      "overflow-wrap": "break-word"
    }
  }, [_vm._v(" " + _vm._s(_vm.task.comment) + " ")])]) : _vm._e()])])]), _vm.widgetFields.resource ? _c('div', {
    staticStyle: {
      "min-width": "207px",
      "border-left": "1px solid #e6e8ec",
      "display": "flex",
      "flex-direction": "column",
      "gap": "16px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "width": "100%"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "width": "176px",
      "border": "1px solid #e6e8ec",
      "border-radius": "8px"
    }
  }, [_c('TaskLinkedToSearch', {
    staticClass: "button-small",
    attrs: {
      "not-found-item-name": _vm.task.linkedUser.name,
      "items": _vm.colleagueSearchItems,
      "custom-placeholder": _vm.$t('AddUser'),
      "small-search": true,
      "input-width": '176px'
    },
    on: {
      "change": _vm.handleSelectedItem
    },
    model: {
      value: _vm.task.linkedUser.id,
      callback: function ($$v) {
        _vm.$set(_vm.task.linkedUser, "id", $$v);
      },
      expression: "task.linkedUser.id"
    }
  }), _c('BaseButton', {
    attrs: {
      "items": _vm.resources,
      "description-key": "description",
      "icon-key": "icon",
      "display-key": "name",
      "display-value": "_id",
      "empty-dropdown-text": _vm.$t('ResourceNotAddedToAddContactAdmin'),
      "label": _vm.$t('Resource').toLowerCase(),
      "size": 'small',
      "border": '0px solid #e6e8ec;',
      "custom-style": 'border-radius: 0 8px 8px 0; padding: 4px 6px; border: none; border-left: 1px solid #e6e8ec;'
    },
    on: {
      "itemSelected": _vm.resourceSelected
    }
  })], 1)]), _c('div', {
    staticStyle: {
      "display": "flex",
      "height": "200px",
      "overflow-y": "auto",
      "flex-direction": "column",
      "gap": "16px",
      "align-items": "center"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "4px"
    }
  }, _vm._l(_vm.task.linkedUsers, function (user) {
    return _c('div', {
      key: user._id,
      class: user.icon ? 'already-booked-resource' : 'free-resource',
      staticStyle: {
        "display": "flex",
        "flex-wrap": "nowrap",
        "gap": "8px",
        "justify-content": "space-between",
        "border-radius": "4px",
        "width": "176px",
        "padding": "4px 8px"
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "flex-direction": "column",
        "gap": "2px"
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "gap": "4px"
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/users_16_16.svg"),
        "alt": ""
      }
    }), _c('div', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(user.name))])])]), _c('img', {
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "src": require('@/assets/images/close.svg'),
        "alt": "remove resource",
        "height": "16",
        "width": "16"
      },
      on: {
        "click": function ($event) {
          return _vm.removeSelectedUser(user);
        }
      }
    })]);
  }), 0), _c('div', {
    key: _vm.resourceBookingUpdater + '-resources',
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "4px"
    }
  }, _vm._l(_vm.task.resources, function (resource) {
    return _c('div', {
      key: resource._id,
      class: resource.icon ? 'already-booked-resource' : 'free-resource',
      staticStyle: {
        "display": "flex",
        "flex-wrap": "nowrap",
        "gap": "8px",
        "justify-content": "space-between",
        "border-radius": "4px",
        "width": "176px",
        "padding": "4px 8px"
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "flex-direction": "column",
        "gap": "2px"
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "gap": "4px"
      }
    }, [_c('img', {
      attrs: {
        "src": require('@/assets/images/pencil_20_20.svg'),
        "height": "16",
        "width": "16",
        "alt": "resource image"
      }
    }), _c('div', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(resource.name))])]), _c('div', {
      staticClass: "font-11-regular",
      staticStyle: {
        "color": "#75787a"
      }
    }, [_vm._v(" " + _vm._s(resource.description) + " ")])]), _c('img', {
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "height": "16",
        "width": "16",
        "src": require('@/assets/images/close.svg'),
        "alt": "remove resource"
      },
      on: {
        "click": function ($event) {
          return _vm.removeSelectedResource(resource);
        }
      }
    })]);
  }), 0)])]) : _vm._e()])], 1), _c('div', {
    key: 'widget-footer-' + _vm.updateWidget,
    staticClass: "task-add-widget-footer"
  }, [_c('div', {
    staticClass: "task-add-widget-footer-content"
  }, [_c('div', {
    staticClass: "task-add-widget-footer-content-section"
  }, [_c('TaskLinkedToSearch', {
    attrs: {
      "disable-clear": true,
      "items": _vm.ownerItems,
      "label": "Owner",
      "not-found-item-name": _vm.task.assignedTo.name,
      "icon-name": "users.svg"
    },
    model: {
      value: _vm.task.assignedTo.userId,
      callback: function ($$v) {
        _vm.$set(_vm.task.assignedTo, "userId", $$v);
      },
      expression: "task.assignedTo.userId"
    }
  })], 1), _vm.widgetFields.privateEntry ? _c('div', {
    staticClass: "task-add-widget-footer-content-private-entry-toggle",
    on: {
      "click": _vm.changePrivateEntryToggle
    }
  }, [_c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": _vm.privateEntryTask ? require('../../assets/images/toggle-active.svg') : require('../../assets/images/toggle-inactive.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("Private") + " " + _vm.$t("Entry").toLowerCase()) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "task-add-widget-footer-buttons"
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "label": _vm.$t('Cancel')
    },
    on: {
      "click": function ($event) {
        return _vm.closeWidget(_vm.taskToEdit, true);
      }
    }
  }), _c('BaseButton', {
    attrs: {
      "size": "large",
      "label": _vm.$t('Save'),
      "primary": true
    },
    on: {
      "click": _vm.submitTask
    }
  })], 1)])] : _vm._e(), _vm.loading ? _c('div', {
    staticClass: "task-add-widget-loading-container"
  }, [_c('div', {
    staticClass: "loader"
  })]) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }