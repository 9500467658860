<template>
  <div style="position: relative">
    <textarea
        @input="(e) => emitValue(e)"
        ref="taskTextArea"
        :value="value"
        class="task-text-area"
        :class="{'task-text-area-empty': !this.value && !error, 'task-text-area-filled': this.value && !error, 'task-text-area-error': error, 'task-text-required': required}"
        oninput='this.style.height = "";this.style.height = (this.scrollHeight + 2) + "px"'
        :placeholder="placeholder"
    />
    <span @click="focusTextArea" v-if="showPlaceholder" class="task-text-area-placeholder">{{ placeholder }}<span v-if="required" style="color: #FF1E24">*</span></span>
    <div v-if="error" class="content-normal" style="color: #ff1e24;">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  name: "TaskTextInput",
  props: {
    value: String,
    placeholder: String,
    error: Boolean,
    errorMessage: String,
    required: Boolean
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  watch: {
    value() {
      this.showPlaceholder = !this.value;
    }
  },
  data() {
    return {
      showPlaceholder: true,
    }
  },
  created() {
    this.showPlaceholder = !this.value;
  },
  methods: {
    focusTextArea() {
      this.$refs.taskTextArea.focus()
    },
    emitValue(e) {
      this.$emit('valueChanged', e.target.value)
    }
  },
}
</script>

<style scoped lang="scss">
@import "src/global_variables";

.task-text-area {
  width: 100%;
  font-size: 20px;
  height: 56px;
  resize: none;
  line-height: 24px;
  font-weight: 400;
  background-color: #FAFAFA;
  font-family: Inter, sans-serif;
  border-bottom: 1px solid black;
  outline: none;
}

.task-text-area:focus {
  border-bottom: 2px solid #16AFB9;
  padding-bottom: 14px;
}

.task-text-area-empty {
  padding: 16px 8px 15px 8px;
  border-bottom: 1px solid black;
}

.task-text-area-filled {
  padding: 16px 8px 14px 8px;
  border-bottom: 2px solid black;
}

.task-text-area-error {
  padding: 16px 8px 14px 8px;
  border-bottom: 2px solid #ff1e24;
}

.task-text-area-placeholder {
  position: absolute;
  left: 8px;
  top: 16px;
  line-height: 24px;
  font-size: 20px;
  font-family: Inter, sans-serif;
  color: $grey900;
}

</style>