<template>
  <div v-if="categories[type]" class="category-selection-container">
    <v-tooltip :open-on-focus="false" bottom v-for="item of categories[type]" :key="item.value">
      <template v-slot:activator="{ on }">
        <button v-on="on" @click="buttonClicked(item)"
                :style="{background: item.value === value ? item.background ? item.background : '#F4F5F7' : ''}"
                :class="{'category-button': value !== item.value}">
          <img v-if="item.icon" width="20" height="20" :src="require(`@/assets/images/${item.icon}`)" alt="type icon"/>
          <span v-else class="category-tag label10" :style="{backgroundColor: item.color ? item.color : '#939597'}">
            {{ $t(`TaskType.${item.translation}.short`) }}
          </span>
          <span :class="{'task-type-text': value !== item.value, 'content-small-semibold': value === item.value}"
                class="content-small">
            <span>{{ $t(`TaskType.${item.translation}.long`) }}</span>
          </span>
        </button>
      </template>
      <span>
          {{  $t(`TaskType.${item.translation}.long`) }}
        </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "TaskCategorySelection",
  props: {
    type: String,
    value: String,
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  data() {
    return {
      categories: {
        'event': [
          {
            translation: "Generic",
            value: "generic",
            color: "#939597",
            background: "#F4F5F7"
          },
          {
            translation: "ShowingTheObject",
            value: "showing_the_object",
            color: "#2B7B6D",
            background: "#F2FFE0"
          },
          {
            translation: "NotaryMeeting",
            value: "notary_meeting",
            color: "#4C8DD9",
            background: "#DBE8F7"
          },
          {
            translation: "Meeting",
            value: "meeting",
            color: "#F81CA0",
            background: "#FFF2F7"
          },
          {
            translation: "DeliveryOfPremises",
            value: "delivery_of_premises",
            color: "#FFD702",
            background: "#FFFACC"
          },
        ],
        'task': [
          {
            translation: "Call",
            value: "call",
            icon: "phone_20_20.svg"
          },
          {
            translation: "Email",
            value: "email",
            icon: "email_20_20.svg"
          },
          {
            translation: "Invoice",
            value: "invoice",
            icon: "invoice.svg"
          },
          {
            translation: "Task",
            value: "task",
            icon: "flag_20_20.svg"
          }
        ]
      },
    }
  },
  methods: {
    buttonClicked(item) {
      if (item.value === this.value) return;
      this.$emit('valueChanged', item.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.category-selection-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 32px;
}

.category-selection-container button {
  all: unset;
  padding: 5px 11px 5px 11px;
  border-top: 1px solid $grey500;
  border-bottom: 1px solid $grey500;
  border-right: 1px solid $grey500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  -webkit-transition: max-width 0.3s ease-in;
  -moz-transition: max-width 0.3s ease-in;
  -o-transition: max-width 0.3s ease-in;
  transition: max-width 0.3s ease-in;
  overflow: hidden;
}

.category-selection-container .category-button {
  max-width: 20px;
}

.category-selection-container button:focus {
  background: $grey;
  max-width: 100%;
}

.category-selection-container .category-button .task-type-text {
  display: none;
}

.category-selection-container .category-button:focus .task-type-text {
  display: block;
}

.category-selection-container button:first-of-type {
  padding: 5px 11px;
  border-left: 1px solid $grey500;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.category-selection-container button:last-of-type {
  border-right: 1px solid $grey500;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.category-tag {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-small-semibold {
  font-weight: 600;
}

</style>