var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "type-selection-container"
  }, _vm._l(_vm.types, function (item) {
    return _c('button', {
      key: item.name,
      style: _vm.getStyleButton(item),
      attrs: {
        "id": 'button-' + item.value
      },
      on: {
        "click": function (e) {
          return _vm.buttonClicked(e, item);
        }
      }
    }, [_c('span', {
      staticClass: "content-small",
      style: _vm.getStyleText(item)
    }, [_vm._v(_vm._s(_vm.$t(item.name)))])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }