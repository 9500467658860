<template>
  <date-pick
    :nextMonthCaption="nextMonthCaption"
    :weekdays="weekdays"
    :prevMonthCaption="prevMonthCaption"
    :months="months"
    @input="updateDate"
    v-model="date"
  >
    <template v-slot:default="{ toggle }">
      <div class="content-date-picker">
        <img
          style="cursor: pointer"
          @click="toggle"
          height="20"
          width="20"
          :src="require('@/assets/images/calendar_20_20.svg')"
          alt=""
        />
        <div class="content-date-picker-content">
          <div style="cursor: pointer" @click="toggle">
            <div class="colored-label label12">{{ $t(label) }}</div>
            <div class="content-normal">{{ formatDate(dates.endDate) }}</div>
          </div>
          <div
            @click="updateFullDayTask"
            style="display: flex; gap: 8px; align-items: center; height: 16px"
          >
            <Checkbox
              @clicked="updateFullDayTask"
              :checked="fullDayTask"
              :customStyle="'width: 16px; height: 16px; margin: 0'"
            />
            <p class="content-small">{{ $t("FullDay") }}</p>
          </div>
        </div>
      </div>
    </template>
  </date-pick>
</template>

<script>
import DatePick from "vue-date-pick";
import Checkbox from "@/components/common/Checkbox";
import moment from "moment";

export default {
  name: "TaskDatePicker",
  props: ["dates", "fullDayTask", "label", "taskCategory"],
  components: {
    Checkbox,
    DatePick,
  },
  data() {
    return {
      date: null,
      nextMonthCaption: this.$t("NextMonth"),
      prevMonthCaption: this.$t("PreviousMonth"),
      weekdays: [this.$t("Mon"), this.$t("Tue"), this.$t("Wed"), this.$t("Thu"), this.$t("Fri"), this.$t("Sat"), this.$t("Sun")],
      months: [
        this.$t("January"),
        this.$t("February"),
        this.$t("March"),
        this.$t("April"),
        this.$t("May"),
        this.$t("June"),
        this.$t("July"),
        this.$t("August"),
        this.$t("September"),
        this.$t("October"),
        this.$t("November"),
        this.$t("December"),
      ],
    };
  },
  created() {
    if (!this.dates.endDate) {
      this.updateDate(moment());
      this.date = moment().format('YYYY-MM-DD');
    } else {
      this.date = moment(this.dates.endDate).format('YYYY-MM-DD')
    }
  },
  methods: {
    updateFullDayTask() {
      this.$emit("changeFullDayToggle", !this.fullDayTask);
    },
    updateDate(date) {
      this.$emit("change", date);
    },
    formatDate(date) {
      return moment(date).format("dddd, DD.MM");
    },
  },
};
</script>

<style lang="scss" scoped>
.content-date-picker {
  min-width: 175.5px;
  display: flex;
  gap: 12px;
}

.content-date-picker-content {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.colored-label {
  color: #75787a;
}

::v-deep .vdpPositionBottom.vdpPositionLeft {
  z-index: 102;
}

::v-deep .vdpCell.today .vdpCellContent { 
  outline: 1px solid #FF5C01;
  //border-radius: 1000px;
  color: #FF5C01;
  font-weight: 600;
}
/* ::v-deep .vdpCell .vdpCellContent {
  color: #000000 !important;
}  */
::v-deep .vdpArrowPrev:after {
    border-right-color: #FF5C01;
}
::v-deep .vdpArrowNext:after {
    border-left-color: #FF5C01;
}
::v-deep .vdpCell.selectable:hover .vdpCellContent,
.vdpCell.selected .vdpCellContent {
    color: #000000;
    //background-color: #000000 !important;
    background: #F4F5F7 !important;
    font-weight: 400;
}

::v-deep .vdpCell.selected .vdpCellContent {
  background: #FF5C01;
  color: #ffffff;
  font-weight: 600;
}

</style>