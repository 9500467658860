var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('date-pick', {
    attrs: {
      "nextMonthCaption": _vm.nextMonthCaption,
      "weekdays": _vm.weekdays,
      "prevMonthCaption": _vm.prevMonthCaption,
      "months": _vm.months
    },
    on: {
      "input": _vm.updateDate
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var toggle = _ref.toggle;
        return [_c('div', {
          staticClass: "content-date-picker"
        }, [_c('img', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "height": "20",
            "width": "20",
            "src": require('@/assets/images/calendar_20_20.svg'),
            "alt": ""
          },
          on: {
            "click": toggle
          }
        }), _c('div', {
          staticClass: "content-date-picker-content"
        }, [_c('div', {
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": toggle
          }
        }, [_c('div', {
          staticClass: "colored-label label12"
        }, [_vm._v(_vm._s(_vm.$t(_vm.label)))]), _c('div', {
          staticClass: "content-normal"
        }, [_vm._v(_vm._s(_vm.formatDate(_vm.dates.endDate)))])]), _c('div', {
          staticStyle: {
            "display": "flex",
            "gap": "8px",
            "align-items": "center",
            "height": "16px"
          },
          on: {
            "click": _vm.updateFullDayTask
          }
        }, [_c('Checkbox', {
          attrs: {
            "checked": _vm.fullDayTask,
            "customStyle": 'width: 16px; height: 16px; margin: 0'
          },
          on: {
            "clicked": _vm.updateFullDayTask
          }
        }), _c('p', {
          staticClass: "content-small"
        }, [_vm._v(_vm._s(_vm.$t("FullDay")))])], 1)])])];
      }
    }]),
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }