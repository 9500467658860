<template>
  <div class="type-selection-container">
    <button :id="'button-' + item.value" @click="(e) => buttonClicked(e, item)" :style="getStyleButton(item)"
            v-for="item of types" :key="item.name">
      <span :style="getStyleText(item)" class="content-small">{{ $t(item.name) }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "TaskTypeSelection",
  props: {
    types: {
      type: Array,
      default() {
        return [
          {name: 'Event', value: 'event'},
          {name: 'Task', value: 'task'},
          {name: 'OpenHouse', value: 'open_house'}
        ]
      }
    },
    type: String,
    value: String,
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  methods: {
    buttonClicked(e, item) {
      if (item.value === this.value) return;
      this.$emit('valueChanged', item.value)
      this.$nextTick(() => {
            document.getElementById('button-' + item.value).focus()
          }
      )
    },
    getStyleText(item) {
      if (item.value === this.value) {
        return this.getTypeSelectedTextStyle();
      }
    },
    getTypeSelectedTextStyle() {
      switch (this.type) {
        case "primary":
          return {
            color: "white",
            fontWeight: '600'
          }
      }
    },
    getStyleButton(item) {
      if (item.value === this.value) {
        return this.getTypeSelectedButtonStyle();
      }
    },
    getTypeSelectedButtonStyle() {
      switch (this.value) {
        case "open_house":
          return {
            background: "#7200E2",
            cursor: 'default'
          }
        default:
          return {
            background: "black",
            cursor: 'default'
          }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.type-selection-container {
  display: flex;
  flex-wrap: nowrap;
  height: 32px;
}

.type-selection-container button {
  all: unset;
  padding: 7px 15px 7px 16px;
  border-top: 1px solid $grey500;
  border-bottom: 1px solid $grey500;
  border-right: 1px solid $grey500;
  cursor: pointer;
}

.type-selection-container button:focus {
  background: $grey;
}

.type-selection-container button:hover {
  background: $grey;
}

.button-primary-selected {
  background: black;
}

.type-selection-container button:first-of-type {
  padding: 7px 15px;
  border-left: 1px solid $grey500;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.type-selection-container button:last-of-type {
  border-right: 1px solid $grey500;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.type-selection-container button span {
  white-space: nowrap;
}
</style>