var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.categories[_vm.type] ? _c('div', {
    staticClass: "category-selection-container"
  }, _vm._l(_vm.categories[_vm.type], function (item) {
    return _c('v-tooltip', {
      key: item.value,
      attrs: {
        "open-on-focus": false,
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on;
          return [_c('button', _vm._g({
            class: {
              'category-button': _vm.value !== item.value
            },
            style: {
              background: item.value === _vm.value ? item.background ? item.background : '#F4F5F7' : ''
            },
            on: {
              "click": function ($event) {
                return _vm.buttonClicked(item);
              }
            }
          }, on), [item.icon ? _c('img', {
            attrs: {
              "width": "20",
              "height": "20",
              "src": require(`@/assets/images/${item.icon}`),
              "alt": "type icon"
            }
          }) : _c('span', {
            staticClass: "category-tag label10",
            style: {
              backgroundColor: item.color ? item.color : '#939597'
            }
          }, [_vm._v(" " + _vm._s(_vm.$t(`TaskType.${item.translation}.short`)) + " ")]), _c('span', {
            staticClass: "content-small",
            class: {
              'task-type-text': _vm.value !== item.value,
              'content-small-semibold': _vm.value === item.value
            }
          }, [_c('span', [_vm._v(_vm._s(_vm.$t(`TaskType.${item.translation}.long`)))])])])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t(`TaskType.${item.translation}.long`)) + " ")])]);
  }), 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }